import React, { Component, useState } from 'react';
import './HowItWorks.scss';
import { Helmet } from 'react-helmet';

function ContractDialog() {
  const [open, setOpen] = useState(false);

  return (
    <>
        <div onClick={() => setOpen(!open)} variant="contained" className={open ? "vis_quoteButton open" : "vis_quoteButton"}>
             <span>{open ? 'Back to Visualizer' : 'Submit Quotes & Contracts'}</span>
        </div>

        {open 
            ? <div style={{ backgroundColor: '#fff', position: 'absolute', width:'100%', height:'100vh' }}>
                <iframe src="https://main.d2kcpizre6wove.amplifyapp.com/" 
                    frameBorder="0" 
                    style={{overflow:'hidden', height:'100vh', width:'100%', zIndex: '99' }} 
                    height="100%" width="100%"
                />
              </div>
            : null
        }

        <iframe src="https://visualizer.agmbasements.com/" frameBorder="0" style={{overflow:'hidden', height:'100vh', width:'100%'}} height="100%" width="100%"></iframe>
    </>
  );
}


export class Visualizer extends Component {
    static propTypes = {
    };

    state = {
        data: {}
    };

    render() {
        return (
            <>
                <Helmet>
                    <title>AGM Basements | Basement Renovations | Warranty</title>
                    <meta name="description" content="AGM Basements has a 4.5-star rating with over 200 Google Reviews as GTA’s top-rated basement renovations we provide 100% customer satisfaction." />
                </Helmet>
                <div className="visualizerFrame">
                    <ContractDialog/>
                </div>
            </>
        );
    }
}

export default Visualizer;