import React from 'react';
import { Link } from "react-router-dom";
import blueCheckmark from "../../Assets/images/checkmark-blue.png";

export const CallToAction = (props) => {

  const { text, guaranteeItems, subtitle, backgroundImage, desc } = props;

    return (
      <section className="home-start" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="d-flex checkmark-box container">
          <div className="cnt">
            <h2>Ready to get started?</h2>
            <p className="weight400">{subtitle}</p>
            <ul>
              {guaranteeItems && guaranteeItems.map((item, index) => (
              <li key={index}>
                <img src={blueCheckmark} className="float-left" /><b style={{ fontWeight: '400', color: '#666' }}>{item}</b>
              </li>
              ))}

            </ul>
            {/* <p>{desc}</p> */} 
          </div>
          <div className="cnt centered-btn">
            {/* <a href="http://bathroomrenovations.com/design" className="blue-btn desktop-btn">
              Get Free Quote
            </a> */}
            {/* { props.isDesign3D && <p>Call {phone} today to schedule your free consultation or Get Free Quote with our 3D Online Kitchen Creator Tool Today!</p>} */}
              <Link to="/get-started" className="blue-btn desktop-btn">
                Get Free Quote
              </Link>
          </div>
        </div>
      </section>
    )
}