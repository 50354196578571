import React, { Component, createRef,  } from "react";
import { Link } from "react-router-dom";
import ModalForm from "./ModalMobieForm";
import Modal from '@material-ui/core/Modal';


import TextField from "@material-ui/core/TextField";
// import Container from "@material-ui/core/Container";
// import Box from "@material-ui/core/Box";
// import Grid from "@material-ui/core/Grid";
// import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import Slide from "react-reveal/Slide";

import axios from "axios";
import { API_URL } from "../Constants/default";

import filesFetcher from "../Services/filesFetcher";

import "../Assets/SCSS/footer.scss";
import Logo from "../Assets/images/bath_logo.svg";

class Footer extends Component {
  static propTypes = {};

  state = {
    first_name: "",
    last_name: "",
    address: "",
    city: "",
    email: "",
    phone: "",
    theSource: "",
    socialLinks: null,
    isSubmitted: false,
    showBottomForm: false,
    isViewerOpen: false,
    isModalFormOpened: false,
    scrollValue: 0,
  };

  isReviewsAllowed = true;

  mount = createRef(null);

  hadleColorViewer = () => {
    this.setState({ isViewerOpen: true });
  };

  componentDidMount() {
    document.addEventListener("scroll", this.handleScroll);

    const fetchServices = async () => {
        const socialLinks = await filesFetcher.fetchSociaLinks();
        this.setState({ socialLinks });
    };

    fetchServices();
  }

  handleScroll = (e) => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;

    this.setState({
      showBottomForm: winScroll < 70 ? false : true,
    });
  };

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleModal = (e) => {
    this.setState({  isModalFormOpened: !this.state.isModalFormOpened, scrollValue: document.documentElement.scrollTop });
  };

  processGetStartedForm = async (e) => {
    if (this.state.isSubmitted === false) {
      // e.preventDefault();

      // Run API submission
      const res = await axios.post(`${API_URL}/customers`, {
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        city: this.state.city,
        email: this.state.email,
        phone: this.state.phone,
      });
      // debugger
      // Then update the state to true
      this.setState(
        {
          isSubmitted: true,
        },
        () => {
          // After state is updated, resubmit form
          this.formRef.dispatchEvent(
            new Event("submit", { cancelable: true, bubbles: true })
          );
        }
      );
    }
  };

  componentWillMount() {
    let vars = {};
    const parts = window.location.href.replace(
      /[?&]+([^=&]+)=([^&]*)/gi,
      function (m, key, value) {
        vars[key] = value;
        if (localStorage) {
          localStorage.setItem("vars", JSON.stringify(vars));
        }
      }
    );

    if ("agmsource" in vars) {
      sessionStorage.setItem("agmsource", vars.agmsource);
    }

    const theSource = localStorage.getItem("vars");
    const parsedSource = theSource && JSON.parse(theSource).agmsource;

    const possibleSources = [
      "1310 news",
      "580 AM",
      "AM900 (Hamilton)",
      "CHCH TV",
      "CHML",
      "CKTB",
      "Company vehicle",
      "CP24",
      "CTV",
      "Home Show",
      "AdWords",
      "Internet Search",
      "Magazine/flier",
      "News Talk 610 (Niagara)",
      "680 News",
      "Referral from previous client (CSR team will collect contact info once connected to the customer)",
      "Social Media",
      "Facebook",
      "Instagram",
      "YouTube",
      "Pinterest",
      "3C Contact Services.",
      "Nexa Call Center",
      "Word of mouth",
    ];

    if (possibleSources.includes(decodeURI(parsedSource))) {
      const decodedURI = decodeURI(parsedSource);
      this.setState({
        theSource: decodedURI,
      });
    }
    // setSource();
  }

  render() {
    const isKitchenApp = process.env.REACT_APP_NAME === "kitchens";
    const isBathroomApp = process.env.REACT_APP_NAME === "bathrooms";
    const isBasementApp = process.env.REACT_APP_NAME === "basements";

    if (window.location.pathname.match('warranty')) {
      this.isReviewsAllowed = false;
    } else {
      this.isReviewsAllowed = true;
    }

    let geoPhone;

    if (process.env.REACT_APP_NAME === "kitchens") {
      geoPhone =
        this.props.data == null
          ? "289-201-2608"
          : this.props.data.kitchen_phone;

    } else if (process.env.REACT_APP_NAME === "basements") {
      geoPhone =
        this.props.data == null
          ? "289-201-2608"
          : this.props.data.bathroom_phone;

    } else {
      geoPhone =
        this.props.data == null
          ? "289-201-2608"
          : this.props.data.bathroom_phone;
    }

    let geoEmail =
      this.props.data == null
        ? "service@agmrenovations.com"
        : this.props.data.email;

    let geoLocation =
      this.props.data && this.props.data.location
        ? this.props.data.location
        : [
            {
              title: "Vaughan",
              address: "45 Tigi Court",
              city: "Concord",
              province: "ON",
              postal: "L4K 5E4",
            },
          ];

    let region = "";

    if (sessionStorage.getItem("region")) {
      region = sessionStorage.getItem("region");
    } else {
      region = "";
    }

    const callToActionText = isKitchenApp 
    ? <>
        AGM simplifies the renovation process, making it easy for you to get the kitchen of your dreams
        right now. With our interactive <a href="https://main.d1rb158hxvfglw.amplifyapp.com/" target="_blank" className="blue">3D Design Creator</a> and ability to provide on-the-spot quotes, we
        make the journey to your new kitchen convenient, fun and most importantly, so easy! 
      </>
     : isBathroomApp ? 
      <>
        {
          region === "calgary" || region === "vancouver" ? (
            <>
              AGM simplifies the renovation process, making it easy for you to get the
              bathroon of your dreams right now. With our interactive 3D Design
              Creator and ability to provide on-the-spot quotes, we make the journey
              to your new bathroom convenient, fun and most importantly, so easy!
            </>
          ) : (
            <>
              Try our <Link to="/get-started" className="blue">3D Creator</Link> and get your new bathroom
              designed and built just for you from the comfort of your home.
            </>
          )
        }
      </>
      : <>
      <strong>AGM's commitment to 360° Kids</strong>
      <br/><br/>
            Book an appointment at one of our design centres to sit down with our designer to create your dream basement and receive your design and a detailed quote on the spot.
            <br/><br/>
            AGM Renovations has proudly partnered up with 360 kids to help children in need today to have an opportunity to become the homeowners of tomorrow. AGM will contribute a portion of deposits made for service or design appointments that are cancelled to 360° Kids to help a child in need.
        </>
      ;

    if (isKitchenApp) {
      return (
        <footer ref={this.mount}>
          <div className="container">
            <div className="footer-boxes">
              <div className="first">
                <img src={Logo} alt="AGM Renovations" />
                <p>{callToActionText}</p>
         
              {this.isReviewsAllowed && (
                <li style={{
                  marginBottom: '5px',
                  fontWeight: '600',
                  fontSize: '16px',
                  listStyle: 'none'
                }}>
                  <Link style={{ color: '#45c0ee',  textDecoration: 'underline' }} to="/reviews" className="">agm renovations reviews</Link>
                </li>
              )}

                <li style={{
                  marginBottom: '5px',
                  fontWeight: '600',
                  fontSize: '16px',
                  listStyle: 'none'
                }}>
                  <a style={{ color: '#45c0ee',  textDecoration: 'underline' }} href="https://agmrenovationsreviews.com" alt="AGM reviews" target="_blank">agmrenovationsreviews.com</a>
                </li>

                <li style={{
                  marginBottom: '40px',
                  fontWeight: '600',
                  fontSize: '16px',
                  listStyle: 'none'
                }}>
                  <a style={{ color: '#45c0ee',  textDecoration: 'underline' }} href="https://agmrenovationsreviews.ca" alt="AGM reviews" target="_blank">agmrenovationsreviews.ca</a>
                </li>

          

                <Link to="/get-started" className="blue-btn desktop-btn">
                  Get Free Quote
                </Link>
              </div>
              <div className="secоnd">
                <ul>
                  <li className="address">
                    {geoLocation[0].address}, {geoLocation[0].title}{" "}
                    {geoLocation[0].province} {geoLocation[0].postal}
                  </li>
                  <li className="phone">
                    <a href={"tel:" + geoPhone}>{geoPhone}</a>
                  </li>
                  <li className="email">
                    <a href={"mailto:" + geoEmail}>{geoEmail}</a>
                  </li>
                </ul>
              </div>

            </div>
            <div className="footer-bottom">
            <div className="socials">
                <a
                  href={this.state.socialLinks ? this.state.socialLinks.find( item => item.type === 'facebook').url : "https://www.facebook.com/AGMRenovationsCompany"}
                  className="soc-btn soc-btn-f"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Facebook
                </a>
                <a
                  href={this.state.socialLinks ? this.state.socialLinks.find( item => item.type === 'tiktok').url : "https://www.tiktok.com/@agm.renovations?_t=8h8ULAriF7e&_r=1"}
                  className="soc-btn soc-btn-tk"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                <svg 
                  style={{
                    position: 'absolute',
                    width: '23px',
                    top: '2px',
                    marginLeft: '-4px'
                  }}
                  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M412.19,118.66a109.27,109.27,0,0,1-9.45-5.5,132.87,132.87,0,0,1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14,23.9,350,16,350.13,16H267.69V334.78c0,4.28,0,8.51-.18,12.69,0,.52-.05,1-.08,1.56,0,.23,0,.47-.05.71,0,.06,0,.12,0,.18a70,70,0,0,1-35.22,55.56,68.8,68.8,0,0,1-34.11,9c-38.41,0-69.54-31.32-69.54-70s31.13-70,69.54-70a68.9,68.9,0,0,1,21.41,3.39l.1-83.94a153.14,153.14,0,0,0-118,34.52,161.79,161.79,0,0,0-35.3,43.53c-3.48,6-16.61,30.11-18.2,69.24-1,22.21,5.67,45.22,8.85,54.73v.2c2,5.6,9.75,24.71,22.38,40.82A167.53,167.53,0,0,0,115,470.66v-.2l.2.2C155.11,497.78,199.36,496,199.36,496c7.66-.31,33.32,0,62.46-13.81,32.32-15.31,50.72-38.12,50.72-38.12a158.46,158.46,0,0,0,27.64-45.93c7.46-19.61,9.95-43.13,9.95-52.53V176.49c1,.6,14.32,9.41,14.32,9.41s19.19,12.3,49.13,20.31c21.48,5.7,50.42,6.9,50.42,6.9V131.27C453.86,132.37,433.27,129.17,412.19,118.66Z"/></svg>
                
                </a>
                <a
                  href={this.state.socialLinks ? this.state.socialLinks.find( item => item.type === 'instagram').url : "https://www.instagram.com/agmrenovationscompany"}
                  className="soc-btn soc-btn-ins"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Instagram
                </a>
                <a
                  href={this.state.socialLinks ? this.state.socialLinks.find( item => item.type === 'youtube').url : "https://www.youtube.com/channel/UCjahxbw8bMUK73g0OJaYm4g"}
                  className="soc-btn soc-btn-yt"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  YouTube
                </a>
                <a
                  href={this.state.socialLinks ? this.state.socialLinks.find( item => item.type === 'linkedin').url : "https://www.linkedin.com/company/agm-basements-renovation-finishing/"}
                  className="soc-btn soc-btn-in"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  LinkedIn
                </a>
              </div>
              <p>

                <Link
                  style={{ margin: '0 5px'}}
                  className="realEstateLink__content blue"
                  to="/real-estate"
                >
                  Real Estate Team
                </Link>

                <a
                  style={{ margin: '0 10px 0 5px'}}
                  className="realEstateLink__content blue"
                  href="/blog"
                >
                  AGM Blog
                </a>

                Copyright &copy; <span className="year">{new Date().getFullYear()}</span>{" "}
                AGM Renovations. All Rights Reserved. |{" "}
                <a href="/privacy">Privacy Policy</a>

                    <span
                      style={{ cursor: 'pointer', color: '#00b1e3', textDecoration: 'underline', fontWeight: 800, marginLeft: '10px' }}
                      className="realEstateLink__content first blue"
                      onClick={()=> {
                        if ((sessionStorage.getItem("countryCode") === "CA" || sessionStorage.getItem("country") === "Canada"))
                        window.open("https://www.financeit.ca/en/direct/payment-plan/YT0yNDc0MTcmZj0mbD0mcD1adk9IV2NtTk5aTlBnRjVRNGhXLTd3JnM9MCZ0PSZ2PTE=/apply");
                      }}
                    >
                      Financing
                    </span>
               
 
                <Link
                  style={{ margin: '0 5px'}}
                  className="realEstateLink__content blue"
                  to="/warranty"
                >
                  Warranty
                </Link>
                <Link
                  style={{ margin: '0 5px'}}
                  className="realEstateLink__content last blue"
                  to="/referrals"
                >
                  Referrals
                </Link>
                {/* <Link
                  style={{ margin: '0 5px'}}
                  className="realEstateLink__content blue"
                  to="/financing"
                >
                  Financing
                </Link> */}

              </p>
            </div>
          </div>

          {this.state.showBottomForm ? (
            <Slide top>
              <div className="floating-form bannerPopUp">
                <div className="container">
                  <div className="offer-top">
                    <h2 className="offer-text">
                    Сall today and save $3,000
                    </h2>{" "}
                    <h2>{geoPhone}</h2>
                  </div>
                  <form
                    ref={(ref) => (this.formRef = ref)}
                    method="POST"
                    id="estimateform"
                    onSubmit={this.processGetStartedForm}
                    action="https://login.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
                  >
                    <div className="form-fields">
                      <div className="float-logo">
                        <a href="/">
                          <img src={Logo} />
                        </a>
                      </div>
                      <input type="hidden" name="oid" value="00D5Y000002U1by" />
                      <input
                        type="hidden"
                        name="retURL"
                        value={
                          isKitchenApp
                            ? "https://agmkitchens.com/thank-you"
                            : "https://bathroomrenovations.com/thank-you"
                        }
                      />

                      <input type="hidden" name="Lead_Origin__c" value="Web" />

                      {this.state.theSource && (
                        <input
                          id="lead_source"
                          name="lead_source"
                          type="hidden"
                          value={this.state.theSource || ""}
                        />
                      )}

                      <input
                        type="hidden"
                        id="Renovation_Type__c"
                        name="Renovation_Type__c"
                        value={isKitchenApp ? "Kitchen" : "Bathroom"}
                      />

                      <TextField
                        id="first_name"
                        label="First Name"
                        variant="outlined"
                        name="first_name"
                        fullWidth
                        onChange={this.handleInputChange}
                        required
                      />

                      <TextField
                        id="last_name"
                        label="Last Name"
                        variant="outlined"
                        name="last_name"
                        fullWidth
                        onChange={this.handleInputChange}
                        required
                      />

                      <TextField
                        id="city"
                        label="City"
                        variant="outlined"
                        name="city"
                        fullWidth
                        onChange={this.handleInputChange}
                      />

                      <TextField
                        id="email"
                        label="Email"
                        variant="outlined"
                        name="email"
                        fullWidth
                        onChange={this.handleInputChange}
                        required
                      />

                      <TextField
                        id="phone"
                        label="Phone"
                        variant="outlined"
                        name="phone"
                        fullWidth
                        onChange={this.handleInputChange}
                      />

                      <Fab
                        style={{
                          maxWidth: '240px',
                          width: '150%'
                        }}
                        variant="extended"
                        color="primary"
                        className="full-width"
                        type="submit"
                      >
                        Request a Consultation
                      </Fab>
                    </div>
                  </form>
                </div>
              </div>
            </Slide>
          ) : null}

          <div className="floating-call-btn">
            <a href={"tel:" + geoPhone}>{geoPhone}</a>
          </div>

          <div className="floating-call-btn-mobile">
            <a style={{ zIndex: '99999' }} href={"tel:" + geoPhone} />
          </div>


          { this.state.showBottomForm
          ? (
              <div onClick={this.handleModal} className="getQuote-btn-mobile">
                <span>Request a Consultation</span>
              </div>
          )
          : null }


          <ModalForm
            isOpen={this.state.isModalFormOpened}
            handleClose={() => {  
              this.setState({ isModalFormOpened: false }); 
              // setTimeout(()=>{window.scrollTo(0, this.state.scrollValue);}, 500);    
            }}
          >
            <div className="container formMobile">
                  <div style={{ textAlign: 'center' }} className="offer-top">
                    <h2  className="offer-text">Сall today and save $3,000</h2>{" "}
                    <h2>{geoPhone}</h2>
                  </div>
                  <form
                  style={{ marginTop: '10px' }}
                    ref={(ref) => (this.formRef = ref)}
                    method="POST"
                    id="estimateform"
                    action="https://login.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
                    onSubmit={this.processGetStartedForm}
                  >
                    <div className="form-fields">
                      <input type="hidden" name="oid" value="00D5Y000002U1by" />
                      <input
                        type="hidden"
                        name="retURL"
                        value={
                          isKitchenApp
                            ? "https://agmkitchens.com/thank-you"
                            : "https://bathroomrenovations.com/thank-you"
                        }
                      />

                      <input type="hidden" name="Lead_Origin__c" value="Web" />

                      {this.state.theSource && (
                        <input
                          id="lead_source"
                          name="lead_source"
                          type="hidden"
                          value={this.state.theSource || ""}
                        />
                      )}

                      <input
                        type="hidden"
                        id="Renovation_Type__c"
                        name="Renovation_Type__c"
                        value={isKitchenApp ? "Kitchen" : "Bathroom"}
                      />

                      <TextField
                        id="first_name"
                        label="First Name"
                        variant="outlined"
                        name="first_name"
                        fullWidth
                        onChange={this.handleInputChange}
                        required
                      />

                      <TextField
                        id="last_name"
                        label="Last Name"
                        variant="outlined"
                        name="last_name"
                        fullWidth
                        onChange={this.handleInputChange}
                        required
                      />

                      <TextField
                        id="city"
                        label="City"
                        variant="outlined"
                        name="city"
                        fullWidth
                        onChange={this.handleInputChange}
                      />

                      <TextField
                        id="email"
                        label="Email"
                        variant="outlined"
                        name="email"
                        fullWidth
                        onChange={this.handleInputChange}
                        required
                      />

                      <TextField
                        id="phone"
                        label="Phone"
                        variant="outlined"
                        name="phone"
                        fullWidth
                        onChange={this.handleInputChange}
                      />

                      <Fab
                        style={{
                          marginBottom: '8px',
                          marginTop: '8px',
                          borderRadius: '6px'
                        }}
                        variant="extended"
                        color="primary"
                        className="full-width"
                        type="submit"
                      >
                        Continue
                      </Fab>
                    </div>
                  </form>
                </div>
          </ModalForm>



        </footer>
      );
    } else if (isBathroomApp) {

      return (
        <footer ref={this.mount}>
          <div className="container">
            <div className="footer-boxes">
              <div className="first">
                <img src={Logo} alt="AGM Renovations" />
                <p>{callToActionText}</p>

                {this.isReviewsAllowed && (
                  <li style={{
                      marginBottom: '5px',
                      fontWeight: '600',
                      fontSize: '16px',
                      listStyle: 'none'
                  }}>
                    <Link style={{ color: '#45c0ee',     textDecoration: 'underline' }} to="/reviews" className="">agm renovations reviews</Link>
                  </li>
                )}

                <li style={{
                  marginBottom: '5px',
                  fontWeight: '600',
                  fontSize: '16px',
                  listStyle: 'none'
                }}>
                  <a style={{ color: '#45c0ee',  textDecoration: 'underline' }} href="https://agmrenovationsreviews.com" alt="AGM reviews" target="_blank">agmrenovationsreviews.com</a>
                </li>

                <li style={{
                  marginBottom: '40px',
                  fontWeight: '600',
                  fontSize: '16px',
                  listStyle: 'none'
                }}>
                  <a style={{ color: '#45c0ee',  textDecoration: 'underline' }} href="https://agmrenovationsreviews.ca" alt="AGM reviews" target="_blank">agmrenovationsreviews.ca</a>
                </li>

                {/* <a href="http://bathroomrenovations.com/design" className="blue-btn desktop-btn">
                    Get Free Quote
                </a> */}

                <Link to="/get-started" className="blue-btn desktop-btn">
                  Get Free Quote
                </Link>
              </div>
              <div className="secоnd">
                <ul>
                  <li className="address">
                    {geoLocation[0].address}, {geoLocation[0].title}{" "}
                    {geoLocation[0].province} {geoLocation[0].postal}
                  </li>
                  <li className="phone">
                    <a href={"tel:" + geoPhone}>{geoPhone}</a>
                  </li>
                  <li className="email">
                    <a href={"mailto:" + geoEmail}>{geoEmail}</a>
                  </li>
                </ul>
              </div>
              {/* <div className="third">
                <h3>Most <span>Asked Questions</span></h3>
              </div> */}
            </div>
            <div className="footer-bottom">
            <div className="socials">
                <a
                  href={this.state.socialLinks ? this.state.socialLinks.find( item => item.type === 'facebook').url : "https://www.facebook.com/AGMRenovationsCompany"}
                  className="soc-btn soc-btn-f"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Facebook
                </a>
                <a
                  href={this.state.socialLinks ? this.state.socialLinks.find( item => item.type === 'tiktok').url : "https://www.tiktok.com/@agm.renovations?_t=8h8ULAriF7e&_r=1"}
                  className="soc-btn soc-btn-tk"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <svg 
                  style={{
                    position: 'absolute',
                    width: '23px',
                    top: '2px',
                    marginLeft: '-4px'
                  }}
                  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M412.19,118.66a109.27,109.27,0,0,1-9.45-5.5,132.87,132.87,0,0,1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14,23.9,350,16,350.13,16H267.69V334.78c0,4.28,0,8.51-.18,12.69,0,.52-.05,1-.08,1.56,0,.23,0,.47-.05.71,0,.06,0,.12,0,.18a70,70,0,0,1-35.22,55.56,68.8,68.8,0,0,1-34.11,9c-38.41,0-69.54-31.32-69.54-70s31.13-70,69.54-70a68.9,68.9,0,0,1,21.41,3.39l.1-83.94a153.14,153.14,0,0,0-118,34.52,161.79,161.79,0,0,0-35.3,43.53c-3.48,6-16.61,30.11-18.2,69.24-1,22.21,5.67,45.22,8.85,54.73v.2c2,5.6,9.75,24.71,22.38,40.82A167.53,167.53,0,0,0,115,470.66v-.2l.2.2C155.11,497.78,199.36,496,199.36,496c7.66-.31,33.32,0,62.46-13.81,32.32-15.31,50.72-38.12,50.72-38.12a158.46,158.46,0,0,0,27.64-45.93c7.46-19.61,9.95-43.13,9.95-52.53V176.49c1,.6,14.32,9.41,14.32,9.41s19.19,12.3,49.13,20.31c21.48,5.7,50.42,6.9,50.42,6.9V131.27C453.86,132.37,433.27,129.17,412.19,118.66Z"/></svg>
                </a>
                <a
                  href={this.state.socialLinks ? this.state.socialLinks.find( item => item.type === 'instagram').url : "https://www.instagram.com/agmrenovationscompany"}
                  className="soc-btn soc-btn-ins"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Instagram
                </a>
                <a
                  href={this.state.socialLinks ? this.state.socialLinks.find( item => item.type === 'youtube').url : "https://www.youtube.com/channel/UCjahxbw8bMUK73g0OJaYm4g"}
                  className="soc-btn soc-btn-yt"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  YouTube
                </a>
                <a
                  href={this.state.socialLinks ? this.state.socialLinks.find( item => item.type === 'linkedin').url : "https://www.linkedin.com/company/agm-basements-renovation-finishing/"}
                  className="soc-btn soc-btn-in"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  LinkedIn
                </a>
              </div>
              <p>

                    <span onClick={this.hadleColorViewer} 
                    className="realEstateLink__content blue"
                    style={{ 
                      fontWeight: '800', 
                      cursor: 'pointer', 
                      color: 'rgb(0 178 227)',
                      display: 'inline',
                      fontSize: '16px',
                      textDecoration: 'underline',
                    }}
                    >
                      Virtual Showroom
                    </span>

                <a
                  style={{ margin: '0 10px 0 5px'}}
                  className="realEstateLink__content blue"
                  href="/blog"
                >
                  AGM Blog
                </a>


                &copy; <span className="year">{new Date().getFullYear()}</span>{" "}
                AGM Renovations. All Rights Reserved. |{" "}
                <a href="/privacy">Privacy Policy</a>


                    <span
                      style={{ cursor: 'pointer', color: '#00b1e3', textDecoration: 'underline', fontWeight: 800, marginLeft: '10px' }}
                      className="realEstateLink__content first blue"
                      onClick={()=> {
                        if ((sessionStorage.getItem("countryCode") === "CA" || sessionStorage.getItem("country") === "Canada"))
                        window.open("https://www.financeit.ca/en/direct/payment-plan/YT0yNDc0MTcmZj0mbD0mcD1adk9IV2NtTk5aTlBnRjVRNGhXLTd3JnM9MCZ0PSZ2PTE=/apply");
                      }}
                    >
                      Financing
                    </span>
               
 
                <Link
                  style={{ margin: '0 5px'}}
                  className="realEstateLink__content blue"
                  to="/warranty"
                >
                  Warranty
                </Link>
                <Link
                  style={{ margin: '0 5px'}}
                  className="realEstateLink__content last blue"
                  to="/referrals"
                >
                  Referrals
                </Link>
              </p>
            </div>
          </div>

          <Modal
            open={this.state.isViewerOpen}
            onClose={() => { this.setState({ isViewerOpen: false }); }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div style={{
              display: '     flex',
              flexWrap:      'wrap',
              flexDirection: 'column-reverse',
              alignContent:  'center',
              marginTop:     '80px',
              width: '80%',
              marginLeft: '10%',
              background: '#fff',
            }}>
              <iframe src={'https://my.matterport.com/show/?m=e6wsnqibi36&cloudEdit=1'} height={680} width={'100%'}/>
              {/* <h5 style={{ textAlign: 'center' }}>Select a photo, experiment with hundreds of colors–and create the space you've always wanted.</h5> */}
              <h2 style={{ textAlign: 'center' }}>Virtual Showroom ®</h2>
            </div>
          </Modal>


          {this.state.showBottomForm ? (
            <Slide top>
              <div className="floating-form bannerPopUp">
                <div className="container">
                  <div className="offer-top">
                    <h2 className="offer-text">Сall today and save $3,000</h2>{" "}
                    <h2>{geoPhone}</h2>
                  </div>
                  <form
                    ref={(ref) => (this.formRef = ref)}
                    method="POST"
                    id="estimateform"
                    action="https://login.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
                    onSubmit={this.processGetStartedForm}
                  >
                    <div className="form-fields">
                      <div className="float-logo">
                        <a href="/">
                          <img src={Logo} />
                        </a>
                      </div>
                      <input type="hidden" name="oid" value="00D5Y000002U1by" />
                      <input
                        type="hidden"
                        name="retURL"
                        value={
                          isKitchenApp
                            ? "https://agmkitchens.com/thank-you"
                            : "https://bathroomrenovations.com/thank-you"
                        }
                      />

                      <input type="hidden" name="Lead_Origin__c" value="Web" />

                      {this.state.theSource && (
                        <input
                          id="lead_source"
                          name="lead_source"
                          type="hidden"
                          value={this.state.theSource || ""}
                        />
                      )}

                      <input
                        type="hidden"
                        id="Renovation_Type__c"
                        name="Renovation_Type__c"
                        value={isKitchenApp ? "Kitchen" : "Bathroom"}
                      />

                      <TextField
                        id="first_name"
                        label="First Name"
                        variant="outlined"
                        name="first_name"
                        fullWidth
                        onChange={this.handleInputChange}
                        required
                      />

                      <TextField
                        id="last_name"
                        label="Last Name"
                        variant="outlined"
                        name="last_name"
                        fullWidth
                        onChange={this.handleInputChange}
                        required
                      />

                      <TextField
                        id="city"
                        label="City"
                        variant="outlined"
                        name="city"
                        fullWidth
                        onChange={this.handleInputChange}
                      />

                      <TextField
                        id="email"
                        label="Email"
                        variant="outlined"
                        name="email"
                        fullWidth
                        onChange={this.handleInputChange}
                        required
                      />

                      <TextField
                        id="phone"
                        label="Phone"
                        variant="outlined"
                        name="phone"
                        fullWidth
                        onChange={this.handleInputChange}
                      />

                      <Fab
                        style={{
                          maxWidth: '240px',
                          width: '150%'
                        }}
                        variant="extended"
                        color="primary"
                        className="full-width"
                        type="submit"
                      >
                        Request a Consultation
                      </Fab>
                    </div>
                  </form>
                </div>
              </div>
            </Slide>
          ) : null}

          <div className="floating-call-btn">
            <a href={"tel:" + geoPhone}>{geoPhone}</a>
          </div>

          <div className="floating-call-btn-mobile">
            <a style={{ zIndex: '99999' }} href={"tel:" + geoPhone} />
          </div>

          { this.state.showBottomForm
          ? (
              <div onClick={this.handleModal} className="getQuote-btn-mobile">
                <span>Request a Consultation</span>
              </div>
          )
          : null }

          <ModalForm
            isOpen={this.state.isModalFormOpened}
            handleClose={() => {  
              this.setState({ isModalFormOpened: false }); 
              // setTimeout(()=>{window.scrollTo(0, this.state.scrollValue);}, 500);    
            }}
          >
            <div className="container formMobile" style={{ padding: '0' }}>
                  <div style={{ textAlign: 'center' }} className="offer-top">
                    <h2 className="offer-text">Сall today and save $3,000</h2>{" "}
                    <h2>{geoPhone}</h2>
                  </div>
                  <form
                  style={{ marginTop: '10px' }}
                    ref={(ref) => (this.formRef = ref)}
                    method="POST"
                    id="estimateform"
                    action="https://login.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
                    onSubmit={this.processGetStartedForm}
                  >
                    <div className="form-fields">

                      <input type="hidden" name="oid" value="00D5Y000002U1by" />
                      <input
                        type="hidden"
                        name="retURL"
                        value={
                          isKitchenApp
                            ? "https://agmkitchens.com/thank-you"
                            : "https://bathroomrenovations.com/thank-you"
                        }
                      />

                      <input type="hidden" name="Lead_Origin__c" value="Web" />

                      {this.state.theSource && (
                        <input
                          id="lead_source"
                          name="lead_source"
                          type="hidden"
                          value={this.state.theSource || ""}
                        />
                      )}

                      <input
                        type="hidden"
                        id="Renovation_Type__c"
                        name="Renovation_Type__c"
                        value={isKitchenApp ? "Kitchen" : "Bathroom"}
                      />

                      <TextField
                        id="first_name"
                        label="First Name"
                        variant="outlined"
                        name="first_name"
                        fullWidth
                        onChange={this.handleInputChange}
                        required
                      />

                      <TextField
                        id="last_name"
                        label="Last Name"
                        variant="outlined"
                        name="last_name"
                        fullWidth
                        onChange={this.handleInputChange}
                        required
                      />

                      <TextField
                        id="city"
                        label="City"
                        variant="outlined"
                        name="city"
                        fullWidth
                        onChange={this.handleInputChange}
                      />

                      <TextField
                        id="email"
                        label="Email"
                        variant="outlined"
                        name="email"
                        fullWidth
                        onChange={this.handleInputChange}
                        required
                      />

                      <TextField
                        id="phone"
                        label="Phone"
                        variant="outlined"
                        name="phone"
                        fullWidth
                        onChange={this.handleInputChange}
                      />

                      <Fab
                        style={{
                          marginBottom: '8px',
                          marginTop: '8px',
                          borderRadius: '6px'
                        }}
                        variant="extended"
                        color="primary"
                        className="full-width"
                        type="submit"
                      >
                        Continue
                      </Fab>
                    </div>
                  </form>
                </div>
          </ModalForm>

        </footer>
      );
    } else if (isBasementApp) {
      return (
        <footer ref={this.mount}>
          <div className="container">
            <div className="footer-boxes">
              <div className="first">
                <img src={Logo} alt="AGM Renovations" />
                <p>{callToActionText}</p>
         
              {this.isReviewsAllowed && (
                <li style={{
                  marginBottom: '5px',
                  fontWeight: '600',
                  fontSize: '16px',
                  listStyle: 'none'
                }}>
                  <Link style={{ color: '#45c0ee',  textDecoration: 'underline' }} to="/reviews" className="">agm renovations reviews</Link>
                </li>
              )}

                <li style={{
                  marginBottom: '5px',
                  fontWeight: '600',
                  fontSize: '16px',
                  listStyle: 'none'
                }}>
                  <a style={{ color: '#45c0ee',  textDecoration: 'underline' }} href="https://agmrenovationsreviews.com" alt="AGM reviews" target="_blank">agmrenovationsreviews.com</a>
                </li>

                <li style={{
                  marginBottom: '40px',
                  fontWeight: '600',
                  fontSize: '16px',
                  listStyle: 'none'
                }}>
                  <a style={{ color: '#45c0ee',  textDecoration: 'underline' }} href="https://agmrenovationsreviews.ca" alt="AGM reviews" target="_blank">agmrenovationsreviews.ca</a>
                </li>

          

                <Link to="/get-started" className="blue-btn desktop-btn">
                  Get Free Quote
                </Link>
              </div>
              <div className="secоnd">
                <ul>
                  <li className="address">
                    {geoLocation[0].address}, {geoLocation[0].title}{" "}
                    {geoLocation[0].province} {geoLocation[0].postal}
                  </li>
                  <li className="phone">
                    <a href={"tel:" + geoPhone}>{geoPhone}</a>
                  </li>
                  <li className="email">
                    <a href={"mailto:" + geoEmail}>{geoEmail}</a>
                  </li>
                </ul>
              </div>

            </div>
            <div className="footer-bottom">
            <div className="socials">
                <a
                  href={this.state.socialLinks ? this.state.socialLinks.find( item => item.type === 'facebook').url : "https://www.facebook.com/AGMRenovationsCompany"}
                  className="soc-btn soc-btn-f"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Facebook
                </a>
                <a
                  href={this.state.socialLinks ? this.state.socialLinks.find( item => item.type === 'tiktok').url : "https://www.tiktok.com/@agm.renovations?_t=8h8ULAriF7e&_r=1"}
                  className="soc-btn soc-btn-tk"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                <svg 
                  style={{
                    position: 'absolute',
                    width: '23px',
                    top: '2px',
                    marginLeft: '-4px'
                  }}
                  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M412.19,118.66a109.27,109.27,0,0,1-9.45-5.5,132.87,132.87,0,0,1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14,23.9,350,16,350.13,16H267.69V334.78c0,4.28,0,8.51-.18,12.69,0,.52-.05,1-.08,1.56,0,.23,0,.47-.05.71,0,.06,0,.12,0,.18a70,70,0,0,1-35.22,55.56,68.8,68.8,0,0,1-34.11,9c-38.41,0-69.54-31.32-69.54-70s31.13-70,69.54-70a68.9,68.9,0,0,1,21.41,3.39l.1-83.94a153.14,153.14,0,0,0-118,34.52,161.79,161.79,0,0,0-35.3,43.53c-3.48,6-16.61,30.11-18.2,69.24-1,22.21,5.67,45.22,8.85,54.73v.2c2,5.6,9.75,24.71,22.38,40.82A167.53,167.53,0,0,0,115,470.66v-.2l.2.2C155.11,497.78,199.36,496,199.36,496c7.66-.31,33.32,0,62.46-13.81,32.32-15.31,50.72-38.12,50.72-38.12a158.46,158.46,0,0,0,27.64-45.93c7.46-19.61,9.95-43.13,9.95-52.53V176.49c1,.6,14.32,9.41,14.32,9.41s19.19,12.3,49.13,20.31c21.48,5.7,50.42,6.9,50.42,6.9V131.27C453.86,132.37,433.27,129.17,412.19,118.66Z"/></svg>
                
                </a>
                <a
                  href={this.state.socialLinks ? this.state.socialLinks.find( item => item.type === 'instagram').url : "https://www.instagram.com/agmrenovationscompany"}
                  className="soc-btn soc-btn-ins"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Instagram
                </a>
                <a
                  href={this.state.socialLinks ? this.state.socialLinks.find( item => item.type === 'youtube').url : "https://www.youtube.com/channel/UCjahxbw8bMUK73g0OJaYm4g"}
                  className="soc-btn soc-btn-yt"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  YouTube
                </a>
                <a
                  href={this.state.socialLinks ? this.state.socialLinks.find( item => item.type === 'linkedin').url : "https://www.linkedin.com/company/agm-basements-renovation-finishing/"}
                  className="soc-btn soc-btn-in"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  LinkedIn
                </a>
              </div>
              <p>

                <Link
                  style={{ margin: '0 5px'}}
                  className="realEstateLink__content blue"
                  to="/real-estate"
                >
                  Real Estate Team
                </Link>

                <a
                  style={{ margin: '0 10px 0 5px'}}
                  className="realEstateLink__content blue"
                  href="/blog"
                >
                  AGM Blog
                </a>

                Copyright &copy; <span className="year">{new Date().getFullYear()}</span>{" "}
                AGM Renovations. All Rights Reserved. |{" "}
                <a href="/privacy">Privacy Policy</a>

                    <span
                      style={{ cursor: 'pointer', color: '#00b1e3', textDecoration: 'underline', fontWeight: 800, marginLeft: '10px' }}
                      className="realEstateLink__content first blue"
                      onClick={()=> {
                        if ((sessionStorage.getItem("countryCode") === "CA" || sessionStorage.getItem("country") === "Canada"))
                        window.open("https://www.financeit.ca/en/direct/payment-plan/YT0yNDc0MTcmZj0mbD0mcD1adk9IV2NtTk5aTlBnRjVRNGhXLTd3JnM9MCZ0PSZ2PTE=/apply");
                      }}
                    >
                      Financing
                    </span>
               
 
                <Link
                  style={{ margin: '0 5px'}}
                  className="realEstateLink__content blue"
                  to="/warranty"
                >
                  Warranty
                </Link>
                <Link
                  style={{ margin: '0 5px'}}
                  className="realEstateLink__content last blue"
                  to="/referrals"
                >
                  Referrals
                </Link>
                {/* <Link
                  style={{ margin: '0 5px'}}
                  className="realEstateLink__content blue"
                  to="/financing"
                >
                  Financing
                </Link> */}

              </p>
            </div>
          </div>

          {this.state.showBottomForm ? (
            <Slide top>
              <div className="floating-form bannerPopUp">
                <div className="container">
                  <div className="offer-top">
                    <h2 className="offer-text">
                    Сall today and save $3,000
                    </h2>{" "}
                    <h2>{geoPhone}</h2>
                  </div>
                  <form
                    ref={(ref) => (this.formRef = ref)}
                    method="POST"
                    id="estimateform"
                    onSubmit={this.processGetStartedForm}
                    action="https://login.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
                  >
                    <div className="form-fields">
                      <div className="float-logo">
                        <a href="/">
                          <img src={Logo} />
                        </a>
                      </div>
                      <input type="hidden" name="oid" value="00D5Y000002U1by" />
                      <input
                        type="hidden"
                        name="retURL"
                        value={"https://agmbasements.com/thank-you"}
                      />

                      <input type="hidden" name="Lead_Origin__c" value="Web" />

                      {this.state.theSource && (
                        <input
                          id="lead_source"
                          name="lead_source"
                          type="hidden"
                          value={this.state.theSource || ""}
                        />
                      )}

                      <input
                        type="hidden"
                        id="Renovation_Type__c"
                        name="Renovation_Type__c"
                        value={"Basement"}
                      />

                      <TextField
                        id="first_name"
                        label="First Name"
                        variant="outlined"
                        name="first_name"
                        fullWidth
                        onChange={this.handleInputChange}
                        required
                      />

                      <TextField
                        id="last_name"
                        label="Last Name"
                        variant="outlined"
                        name="last_name"
                        fullWidth
                        onChange={this.handleInputChange}
                        required
                      />

                      <TextField
                        id="city"
                        label="City"
                        variant="outlined"
                        name="city"
                        fullWidth
                        onChange={this.handleInputChange}
                      />

                      <TextField
                        id="email"
                        label="Email"
                        variant="outlined"
                        name="email"
                        fullWidth
                        onChange={this.handleInputChange}
                        required
                      />

                      <TextField
                        id="phone"
                        label="Phone"
                        variant="outlined"
                        name="phone"
                        fullWidth
                        onChange={this.handleInputChange}
                      />

                      <Fab
                        style={{
                          maxWidth: '240px',
                          width: '150%'
                        }}
                        variant="extended"
                        color="primary"
                        className="full-width"
                        type="submit"
                      >
                        Request a Consultation
                      </Fab>
                    </div>
                  </form>
                </div>
              </div>
            </Slide>
          ) : null}

          <div className="floating-call-btn">
            <a href={"tel:" + geoPhone}>{geoPhone}</a>
          </div>

          <div className="floating-call-btn-mobile">
            <a style={{ zIndex: '99999' }} href={"tel:" + geoPhone} />
          </div>


          { this.state.showBottomForm
          ? (
              <div onClick={this.handleModal} className="getQuote-btn-mobile">
                <span>Request a Consultation</span>
              </div>
          )
          : null }


          <ModalForm
            isOpen={this.state.isModalFormOpened}
            handleClose={() => {  
              this.setState({ isModalFormOpened: false }); 
              // setTimeout(()=>{window.scrollTo(0, this.state.scrollValue);}, 500);    
            }}
          >
            <div className="container formMobile">
                  <div style={{ textAlign: 'center' }} className="offer-top">
                    <h2  className="offer-text">Сall today and save $3,000</h2>{" "}
                    <h2>{geoPhone}</h2>
                  </div>
                  <form
                  style={{ marginTop: '10px' }}
                    ref={(ref) => (this.formRef = ref)}
                    method="POST"
                    id="estimateform"
                    action="https://login.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
                    onSubmit={this.processGetStartedForm}
                  >
                    <div className="form-fields">
                      <input type="hidden" name="oid" value="00D5Y000002U1by" />
                      <input
                        type="hidden"
                        name="retURL"
                        value={"https://agmbasements.com/thank-you"}
                      />

                      <input type="hidden" name="Lead_Origin__c" value="Web" />

                      {this.state.theSource && (
                        <input
                          id="lead_source"
                          name="lead_source"
                          type="hidden"
                          value={this.state.theSource || ""}
                        />
                      )}

                      <input
                        type="hidden"
                        id="Renovation_Type__c"
                        name="Renovation_Type__c"
                        value={"Basement"}
                      />

                      <TextField
                        id="first_name"
                        label="First Name"
                        variant="outlined"
                        name="first_name"
                        fullWidth
                        onChange={this.handleInputChange}
                        required
                      />

                      <TextField
                        id="last_name"
                        label="Last Name"
                        variant="outlined"
                        name="last_name"
                        fullWidth
                        onChange={this.handleInputChange}
                        required
                      />

                      <TextField
                        id="city"
                        label="City"
                        variant="outlined"
                        name="city"
                        fullWidth
                        onChange={this.handleInputChange}
                      />

                      <TextField
                        id="email"
                        label="Email"
                        variant="outlined"
                        name="email"
                        fullWidth
                        onChange={this.handleInputChange}
                        required
                      />

                      <TextField
                        id="phone"
                        label="Phone"
                        variant="outlined"
                        name="phone"
                        fullWidth
                        onChange={this.handleInputChange}
                      />

                      <Fab
                        style={{
                          marginBottom: '8px',
                          marginTop: '8px',
                          borderRadius: '6px'
                        }}
                        variant="extended"
                        color="primary"
                        className="full-width"
                        type="submit"
                      >
                        Continue
                      </Fab>
                    </div>
                  </form>
                </div>
          </ModalForm>



        </footer>
      );
    }
  }
}

export default Footer;
