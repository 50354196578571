import React from 'react';

import { Link } from "react-router-dom";

export const CallToAction = (props) => {
  const { text, otherText, className } = props;

  let region = '';
  if (sessionStorage.getItem("region")) {
    region = sessionStorage.getItem("region");
  } else {
    region = ''
  }

  const renderButton = () =>{
      return <Link to="/get-started" className="blue-btn margin-auto">Get Started</Link>
  }

  return (
    <section className={`about-section-3 ${className || ''}`}>
      <div className="container">
        <div className="cnt">
          <h3>
            <span>Get Your Quote</span>
          </h3>

          {otherText ? <p>
            <span>{otherText}</span>
          </p> : <></>}
          <p>
            <span>{text}</span>
          </p>

          {renderButton()}
        </div>
      </div>
    </section>
  )
}