import React from 'react';
import { Link } from "react-router-dom";

export const WorkSteps = ({ items }) => {

  const email = localStorage.getItem('email');
  const pass  = localStorage.getItem('pass');

  let region = '';
  if (sessionStorage.getItem("region")) {
    region = sessionStorage.getItem("region");
  } else {
    region = ''
  }

  const renderButton = () => { 

      return <Link to="/get-started" className="blue-btn margin-auto">Get Started</Link>

  }

  return (
    <section className="work-steps">
      <div className="container">
        <ul>
          {items.map((item, index) => (
            <li data-number={index + 1} key={index}>
            <div className="cnt">
              <h3>{item.title}</h3>
              <p>{item.description}</p>
            </div>
            <img alt='' {...item.imgProps} />
          </li>
          ))}
        </ul>
        {renderButton()}
      </div>
    </section>
  )
}