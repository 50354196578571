import React from 'react';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import {
  TopSection,
  WorkSteps,
  CreatorFeaturesBsm,
  Enjoy,
} from '../../Containers/HowItWorks';

import bannerLaptop from "../../Assets/images/kitchens/howitworks/laptop1.png";

// import HiwStepOne from "../../Assets/images/hiw_step_1.png";
// import HiwStepTwo from "../../Assets/images/kitchens/howitworks/step2.jpeg";
// import HiwStepThree from "../../Assets/images/kitchens/howitworks/step3.jpeg";
// import HiwStepFour from "../../Assets/images/hiw_step_4.png";
// import HiwStepFive from "../../Assets/images/hiw_step_5.png";
// import HiwStepSix from "../../Assets/images/hiw_step_6.jpg";
// import HiwStepSeven from "../../Assets/images/kitchens/howitworks/step7.jpeg";
import Viz from "../../Assets/images/basements/vis.jpg";

import HiwStepOne from "../../Assets/images/basements/howitworks/establish_wants_and_needs.jpeg";
import HiwStepTwo from "../../Assets/images/basements/howitworks/design_center_appt.jpeg";
import HiwStepThree from "../../Assets/images/basements/howitworks/pre_build_inspection.jpeg";
import HiwStepFour from "../../Assets/images/basements/howitworks/finish_selection.jpeg";
import HiwStepFive from "../../Assets/images/basements/howitworks/the_build.jpeg";
import HiwStepSix from "../../Assets/images/basements/howitworks/project_completion.jpg";
import HiwStepSeven from "../../Assets/images/basements/howitworks/happy_family_with_children.jpeg";



import enjoyImg from "../../Assets/images/basements/our-works/gallery/OW88ed.jpg";

function capitalizeWords(str) {
  return str
    .split(' ')                     // Split the string by spaces into an array of words
    .map(word =>                    // Map over each word in the array
      word.charAt(0).toUpperCase() + // Capitalize the first letter of the word
      word.slice(1).toLowerCase()    // Lowercase the rest of the word
    )
    .join(' ');                      // Join the array back into a single string
}

export const HowItWorksPage = () => {

  const topSectionProps = {
    className: 'kitchens__how-works',
    bannerImgProps: {
      alt: 'laptop with kitchen on the screen',
      src: bannerLaptop,
    },
  };

  const workStepsItems = [
    {
      title: capitalizeWords('ESTABLISH YOUR WANTS AND NEEDS'),
      description: 'With any successful project, doing your homework upfront guarantees success. Consider how you want to live in your new basement. Will this be a gathering place for family and friends? Perhaps with a bar or entertainment area? Do you want a home theatre, home gym or wine room? A kitchen or guest suite? Are you looking for an income-generating rental apartment? Contact us and we can get started on making your dream a reality.',
      imgProps: {
        src: HiwStepOne,
        style: {width: '300px' }
      }
    },
    {
      title: capitalizeWords('VIRTUAL DESIGN APPOINTMENT'),
      description: `An experienced Designer will present the most creative and most functional 3D concept for your basement, based on your Wish List and our years of experience in optimal basement design. Changes can be made in real-time, so you can fully visualize your basement come to life. They will walk you through an itemized list of our inclusive package of high-quality building materials, and all included features and components. Once the design is just right, we'll provide the detailed agreement and payment structure.`,
      imgProps: {
        src: HiwStepTwo,
        style: {width: '300px' }
      }
    },
    {
      title: capitalizeWords('TECHNICAL WALK-THROUGH INSPECTION (SITE VISIT)'),
      description: 'One of our seasoned Project Managers along with a Designer will review your space with the Design Concept, assessing structural, plumbing and mechanical layouts. We’ll capture any necessary adjustments to the design, or changes you have decided on since the Virtual Design Appointment. All of this information will be used to create a Final Design that will be the blueprint for the build.',
      imgProps: {
        src: HiwStepThree,
        style: {width: '300px' }
      }
    },
    {
      title: capitalizeWords('SELECT FINISHES'),
      description: 'Our Designers can guide you through our array of high-quality included finishes and other optional choices to make your basement suit your personal style.',
      imgProps: {
        src: HiwStepFour,
        style: {width: '300px' }
      }
    },
    {
      title: capitalizeWords('THE BUILD'),
      description: 'Your dedicated Project Manager is all set to go! We order all of the required materials for your project and will have them delivered. We do ask that you provide space in part of your garage to keep these materials clean and safe. Alternately, you can elect to have a storage container delivered for the materials. You’ll receive regular updates from our CC team and your dedicated PM during the course of the build.',
      imgProps: {
        src: HiwStepFive,
        style: {width: '300px' }
      }
    },
    {
      title: capitalizeWords('PROJECT COMPLETION'),
      description: 'We make sure you are completely satisfied. Know that with our warranty and commitment to you, we’ll come back to fix any minor imperfections that may arise. Our dedicated team of handymen can address any outstanding items to ensure your project is just perfect. We’re so proud of our work, we love to take photography of your finished project.',
      imgProps: {
        src: HiwStepSix,
        style: {width: '300px' }
      }
    },
    {
      title: capitalizeWords('BECOME ONE OF OUR MANY SATISFIED CLIENTS'),
      description: 'But don’t take our word for it, see our Google Reviews of hundreds of satisfied customers. More in fact, than any of our competitors. We offer you the AGM Difference and would be honored for you to choose us for your basement renovation project.',
      imgProps: {
        src: HiwStepSeven,
        style: {width: '300px' }
      }
    },
  ]

  const creatorFeaturesItems = [
    {
      text: (
        <>
          {/* <strong>3D Design</strong> */}
          <Link to="/visualize" style={{ width: '100%',   maxWidth: '400px',
  margin: '0 auto',
  marginTop: '50px', textAlign: 'center' }} className="blue-btn margin-auto">3D Design</Link>
          {/* <br/> Upload your kitchen dimensions
                                          and see your space in 3D. From here, it’s easy to choose
                                          fixtures, flooring and finishes, so you can design your dream
                                          kitchen instantly. */}
        </>
      ),
      imgProps: {
        src: Viz,
      }
    },
  ]

  const enjoyProps = {
    className: 'kitchen__enjoy',
    title: <><span>Indulge In Your</span> New Basement</>,
    imgProps: {
      src: enjoyImg,
      alt: 'Basement',
    }
  }

  return (
    <>
      <Helmet>
        <meta name="robots" content="index"/>
        <meta name="description" content={`With just a few questions of what your space looks like now and what your dream (Insert_Reno_Type) would look like, we can begin guiding you through the available luxury finishes to design your dream (Insert_Reno_Type), get quoted instantly and schedule a start date today`} />
        <meta name="keywords" content={`AGM Process, AGM How It Works, AGM Design, AGM Materials, AGM Finishes, AGM Start Date, AGM Quote`} />
        <title>AGM Kitchens Renovations  |  AGM Renovations How It Works</title>
      </Helmet>

      <TopSection {...topSectionProps}  />
      <WorkSteps items={workStepsItems}/>
      <CreatorFeaturesBsm items={creatorFeaturesItems}/>
      <Enjoy {...enjoyProps} />
    </>
  )
}