import React, { useRef, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  TopSection,
  Intro,
  HowItWorks,
  Testimonials,
  OurAwards,
  LatestWorks,
  CallToAction,
} from '../../Containers/Home';

// import sectionBackgroundCloset from '../../Assets/images/home-page-closets.jpg';
// import sectionBackgroundBlinds from '../../Assets/images/blinds_bg.jpg';
// import sectionBackground from '../../Assets/images/home-page-kitchens.jpg';

import ModalReferral from '../../Common/modalReferral';

// import po from '../../Assets/images/po.png';
// import baneerOffer from '../../Assets/images/kitchensspecoffer.gif';
// import sectionBackground from '../../Assets/images/kitchens/home/top-section-bg90.jpg';
import hiwImg1 from '../../Assets/images/Wood_Floor_Installation.png';
import hiwImg2 from "../../Assets/images/select_samples.png";
import hiwImg3 from '../../Assets/images/realtor_icon-02.png';

import LatestWorkOne from "../../Assets/images/basements/our-works/gallery/OW5.jpg";
import LatestWorkTwo from "../../Assets/images/basements/our-works/gallery/DSC03832.jpg";
import LatestWorkThree from "../../Assets/images/basements/our-works/gallery/DSC03833.jpg";
import LatestWorkFour from "../../Assets/images/basements/our-works/gallery/DSC03816.jpg";
import LatestWorkFive from "../../Assets/images/basements/our-works/gallery/OW4.jpg";

import callToActionBackground from '../../Assets/images/basements/home/home-footer-bsm.png';

// import TestimonialsReviewerFour from './../../Assets/testimonials_images/pic-01 2.png';
// import TestimonialsReviewerTen from './../../Assets/testimonials_images/pic-24.png';
// import TestimonialsReviewerEleven from './../../Assets/testimonials_images/pic-03.png';

import UserPhotoTwo from './../../Assets/testimonials_images/pic-12.png';
import UserPhotoTen from './../../Assets/testimonials_images/pic-05.png';
import UserPhotoSix from './../../Assets/testimonials_images/pic-13.png';

const urlSearchParams = new URLSearchParams(window.location.search);

function removeParam(paramName) {
  let searchParams = urlSearchParams;
  searchParams.delete(paramName);

  if (window.history.replaceState) {
    let searchString = searchParams.toString().length > 0 ? '?' + searchParams.toString() : '';
    let newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + searchString + window.location.hash;
    window.history.replaceState(null, '', newUrl);
  }
}
const handler = { isShowPromo: false };

const Banners = () => {

  // const timeBannerShown = 7000;
  // const bannersAmount = 2;
  // const [bannerIndex, setIndex] = React.useState(1);
  const promoRef = useRef(null);


  const handleScroll = (e) => {

    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    // const promoRef = document.getElementById('promo2Elem');

    if (promoRef.current) {
      if (winScroll >= 70) {
        if (!handler.isShowPromo) {
          promoRef.current.classList.add('visible');
          handler.isShowPromo = true;
        }
      } else {
        if (handler.isShowPromo) {
          promoRef.current.classList.remove('visible');
          handler.isShowPromo = false;
        }
      }
    }
    
  };
  document.addEventListener("scroll", handleScroll);

  // React.useEffect(() => {
  //   setTimeout(() => {
  //     let newIndex = bannerIndex + 1;

  //     if (newIndex === bannersAmount) newIndex = 0;
  //     setIndex(newIndex);
  //   }, timeBannerShown);
  // }, [bannerIndex]);




  return (
    <div>
      {/* {bannerIndex === 0 ? <img ref={promoRef} className={handler.isShowPromo ? "promo2 visible" : "promo2"}  id="promo2Elem"  src={baneerOffer}/> : null} */}
      {/* {bannerIndex === 1 ? <img ref={promoRef} className={handler.isShowPromo ? "promo2 ref visible" : "promo2 ref"}  id="promo2Elem" style={{ cursor: 'pointer', zIndex: '100' }} onClick={referralHandler} src={baneerOfferRef}/> : null} */}
      
      <Link to="/get-started">
          {/* <img className="promo2" id="promo2Elem" src={baneerOffer}/> */}
          {/* <img ref={promoRef} className={"promo2"}  id="promo2Elem"  src={baneerOffer}/> */}
       </Link>
   
   </div>
  );
};

const ReferralModal = () => {
  const timeBannerShown = 7000;
  const [isDialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {

    if (!window.location.pathname.match("referrals") && !sessionStorage.bannerSkipped && !window.banerREfStart) {

      setTimeout( () => {
        if (!window.location.pathname.match("referrals") && !sessionStorage.bannerSkipped && !window.banerREfStart) {
          setDialogOpen(true);
          window.banerREfStart = true;
        }
      }, timeBannerShown );

    }
  }, []);

  return (
    <ModalReferral  open={isDialogOpen} 
      handleClose={() => {
        sessionStorage.setItem('bannerSkipped', true); 
        setDialogOpen(false); 
      }}
    />
  );

};

export const HomePage = (props) => {


  const topSectionProps = {
    className: 'home-section--kitchen basement',
    title: 'Basement Renovations',
    subtitle: 'Done Right!',
    // listTitle: 'Hassle Free Quote without:',
    // listItems: ['Dealing with contractors', 'Inviting strangers to your home', 'Visiting multiple showrooms', 'Wasting time!'],
    // backgroundImage: sectionBackground,
    backgroundSize: '100%'
  }

  const howItWorksItems = [
    {
      title: 'Technical Walk-through Inspection',
      text: 'One of our seasoned Project Managers along with a Designer will review your space with the Design Concept, assessing structural, plumbing and mechanical layouts.',
      useBackgroundImage: true,
      imgProps: {
        src: hiwImg2,
      },
    },
    {
      title: 'The Build',
      text: 'Your dedicated Project Manager is all set to go! We order all of the required materials for your project and will have them delivered.',
      useBackgroundImage: true,
      imgProps: {
        src: hiwImg1,
      },
    },
    {
      title: 'Project Completion',
      text: 'We make sure you are completely satisfied. Know that with our warranty and commitment to you, we’ll come back to fix any minor imperfections that may arise.',
      imgProps: {
        src: hiwImg3,
      },
    },
  ];

  const latestWorksItems = [
    LatestWorkOne,
    LatestWorkTwo,
    LatestWorkThree,
    LatestWorkFour,
    LatestWorkFive,
  ];

  const callToActionProps = {
    text: 'Speak to our renovation team for a free consultation and get your quote instantly. It all starts with one click. We guarantee a commitment to excellent to our customers including but not limited to:',
    subtitle: "Choosing AGM Renovations means guaranteed...",
    guaranteeItems: [
      'Completing our projects on time',
      'Completing our projects within budget',
      '5 Year Baseboard to Baseboard Warranty',
      'No added or hidden costs',
      'Decades of customer satisfaction'
    ],
    backgroundImage: callToActionBackground,
    desc: <>
      Try our 3D Creator and get your new kitchen <b className="red">designed and built just for you</b> from the
      comfort of your home.
    </>
  }

  // const introProps = {
  //   subtitle: 'Expert kitchen renovations since 1998.',
  //   text: "In Canada, around 97.5% of renovation projects are either finished late or over budget. Only 2.5% of projects completed properly. As the oldest company in the industry, we are proudly part of this elite group, with a proven track record of finishing kitchen renovations on time and on budget.",
  // }


  // import UserPhotoThirteen from './../../Assets/Images/google_reviews/pic-11.png';
  // import UserPhotoFive from './../../Assets/Images/google_reviews/pic-09.png';



  const testimonialsItems = [
    {
      text:  "We recently had our basement finished by AGM. We felt very comfortable throughout the project. Their workers strive to do their best and work diligently! It does show in the final finished basement. Our entire family is in agreement that AGM did a great job not only to meet our expectations but in fact exceeded it! We definitely would recommend AGM to everyone!",
      name: "Andrew M.",
      photo: UserPhotoSix,
    },
    {
      text: "We hired AGM to create a small basement suite for our son.  From start to finish it was the best experience.  From the design phase, to the costing, to the actual construction I couldn’t have ask for anything more. Everyone was very very professional and accommodating.",
      name: "Valerie A.",
      photo: UserPhotoTwo,
    },
    {
      text: "AGM is a mark of great workmanship! We are very pleased with our finished basement living space. The process was streamlined and the work progressed from start to finish in a very professional manner! There were no surprises as every aspect of the work was planned at the design stage! We thank all the trades people for their wonderful work in our basement! Keep up the good work!",
      name: "Srikanth R.",
      photo: UserPhotoTen,
    }
  ]

  const newIntro = {
    countryCode: props?.data?.countryCode,
    title: "Welcome to your Basement Professionals!",
    subtitle: "AGM Basements has listened to customers for over 20 years.",
    text: "As the first company to specialize exclusively in basement renovation, AGM Basements has listened to customers for over 20 years, and has developed a tried and true process focused on you. We’re here to help you realize your dream basement to make the best decisions for your investment, for your home and your family."
  }


    if (!window.URLSearchParams)
      (function (w) {

        w.URLSearchParams = w.URLSearchParams || function (searchString) {
          var self = this;
          self.searchString = searchString;
          self.get = function (name) {
            var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(self.searchString);
            if (results == null) {
              return null;
            } else {
              return decodeURI(results[1]) || 0;
            }
          };
        }

      })(window)


    const params = Object.fromEntries(urlSearchParams.entries());
    let childs = <>
            {/* <img src={po} alt="" className="home-page_po-image"/> */}
            <Banners/>
    </>;

    if (params && ['closet_org', 'closetorg'].includes(params.option) || 
        sessionStorage.getItem("option") && ['closet_org', 'closetorg'].includes(sessionStorage.getItem("option"))) {

      if (!sessionStorage.getItem("option") || params.option && sessionStorage.getItem("option") !== params.option) 
        sessionStorage.setItem("option", params.option);

      // topSectionProps.backgroundImage = sectionBackgroundCloset;
      topSectionProps.className += ' closets';
      topSectionProps.title = 'Custom Closets';
      topSectionProps.noPhoto = true;
      childs = <></>;

      if (params && params.option) removeParam('option');

      window.location.href = '/custom_closets';

    } else  if (params && ['blinds'].includes(params.option) || 
        sessionStorage.getItem("option") && ['blinds'].includes(sessionStorage.getItem("option"))) {

      if (!sessionStorage.getItem("option") || params.option && sessionStorage.getItem("option") !== params.option) 
        sessionStorage.setItem("option", params.option);

      // topSectionProps.backgroundImage = sectionBackgroundBlinds;
      topSectionProps.className += ' blinds';
      topSectionProps.title = 'AGM Blinds';
      topSectionProps.subtitle = '';
      topSectionProps.noPhoto = true;
      childs = <></>;

      if (params && params.option) removeParam('option');

      window.location.href = '/blinds';
    } 

    // console.log('topSectionProps', topSectionProps);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex"/>
        <title>{process.env.REACT_APP_TITLE}</title>
      </Helmet>

      <TopSection {...topSectionProps} location={props.location}>
        {childs}
      </TopSection>
      <Intro {...newIntro} isKitchenApp={false} isBathroomApp={false}/>
      <HowItWorks items={howItWorksItems}/>
      <Testimonials items={testimonialsItems}/>
      <OurAwards/>
      <LatestWorks items={latestWorksItems}/>
      <CallToAction {...callToActionProps} data={props.data}/>
      {/* <ReferralModal/> */}
    </>
  )
}
